import React, { useState, useEffect } from "react";
// import { Link } from "gatsby";
// import CiCLogo from '../images/CiC-Logo-1.webp';
import LogoLogo from "../images/CiC-Logo-logo.png";
import LogoC1 from "../images/CiC-Logo-c1.png";
import LogoI from "../images/CiC-Logo-i.png";
import LogoText from "../images/CiC-Logo-text.png";
import HamburgerIcon from "../images/menu-icon.png";
import CloseButtonIcon from "../images/close_btn.svg";
import "./header.css";
import { Phone } from 'lucide-react';
import Aos from "aos";
import "aos/dist/aos.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link, navigate } from 'gatsby';
import { useLocation } from "@reach/router";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);


  //====================== Home Transition ========================//
 const [homeTransition, setHomeTransition] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  
  const handleHomeTransition = (e, prop) => {
    let xPos = 0;
    
    if (typeof window !== 'undefined') {  // Check if window is available
      // Get click position
      if (window.innerWidth < 1100) {
        xPos = e.clientX;
      } else {
        xPos = e.clientX - 20;
      }
      const yPos = e.clientY;

      // Set CSS variables based on click position
      document.documentElement.style.setProperty('--xPos', `${xPos}px`);
      document.documentElement.style.setProperty('--yPos', `${yPos}px`);

      if (prop === 'home') {
        if (currentPath === '/') {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, '', '/');
          setHomeTransition(true);
          setTimeout(() => {
            navigate('/');
          }, 700);
        }
      } else if (prop === 'about-us') {
        if (currentPath === '/about-us') {
          setHomeTransition(false);
        } else {
          e.preventDefault();
          window.history.pushState({}, '', '/about-us');
          setHomeTransition(true);
          setTimeout(() => {
            navigate('/about-us');
          }, 700);
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (homeTransition) {
        setHomeTransition(false);
      }
    }, 1500);
  }, [homeTransition]);

  // =================== End of Home Transition ==================== //

  const [navPadding, setNavPadding] = useState('10px 5px');

  const handleScroll = () => {
    if (typeof window !== 'undefined') {  // Check if window is available
      if (window.scrollY > 50) {
        setNavPadding('3px 1px');
      } else {
        setNavPadding('10px 5px');
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {  // Check if window is available
      window.addEventListener('scroll', handleScroll);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMenuOpen((prev) => !prev); // Simplified toggle logic
  };

  useEffect(() => {
    Aos.init({ duration: 2000, easing: 'ease-in-out' });

    // Simulate loading time before showing header
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && isMenuOpen) {  // Check if window is available
      window.scrollTo(0, 0);
    }
  }, [isMenuOpen]);

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdown-container") &&
        !event.target.closest(".dropdown-btn")
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="main-content">
      <nav className="nav-2" style={{ padding: navPadding }}>
      {homeTransition? <div className='main-content-test'></div> : null}
        <div className="logo-2">
          <a href="/">
            <img data-aos="slide-right" data-aos-duration="500" className="LogoLogo" src={LogoLogo} alt="Logo" />
            <img data-aos="slide-right" data-aos-duration="500" className="LogoC1" src={LogoC1} alt="Logo" />
            <img data-aos="slide-down" data-aos-duration="500" className="LogoI" src={LogoI} alt="Logo" />
            <img data-aos="slide-left" data-aos-duration="500" className="LogoC2" src={LogoC1} alt="Logo" />
            <img data-aos="zoom-in" data-aos-duration="500" className="LogoText" data-aos-delay='500' src={LogoText} alt="Logo" />
          </a>
        </div>

        {isMenuOpen && (
          <>
            <div className="close-button" onClick={handleMobileMenuToggle}>
              <img src={CloseButtonIcon} alt="Close Button" />
            </div>
          </>
        )}

        <ul className={`menu ${isMenuOpen ? "open" : ""}`}>

        
          <li className="lia testClass" data-aos="fade-down" data-aos-duration="250" data-aos-delay='200'>
            {/* <AniLink paintDrip to="/" hex="#001033">HOME</AniLink> */}
            <Link to="/" onClick={(e)=>handleHomeTransition(e, 'home')}>HOME</Link>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='450'>
            {/* <AniLink paintDrip to="/about-us" hex="#001033">ABOUT US</AniLink> */}
            <Link to="/about-us" onClick={(e)=>handleHomeTransition(e, 'about-us')}>ABOUT US</Link>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='700'>
            <AniLink paintDrip hex="#001033" to="/our-segments">OUR SEGMENTS</AniLink>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='950'>
            <AniLink paintDrip hex="#001033" to="/sustainability">SUSTAINABILITY</AniLink>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='1200'>
            <AniLink paintDrip hex="#001033" to="/media-room">MEDIA ROOM</AniLink>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='1450'>
            <AniLink paintDrip hex="#001033" to="/investor-desk">INVESTOR DESK</AniLink>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='1700'>
            <AniLink paintDrip hex="#001033" to="/careers-page">CAREERS</AniLink>
          </li>

          <li data-aos="fade-down" data-aos-duration="250" data-aos-delay='1750'>
            <AniLink paintDrip hex="#001033" to="/contact-us"><Phone /></AniLink>
          </li>

          <li className="search-box" >
            <button className="btn-search"><i className="fas fa-search"></i></button>
            <input type="text" className="input-search" placeholder="Type to Search..." />
          </li>
        </ul>

        <div className="hamburger-img">
          <img
            className="mobileIcon"
            onClick={handleMobileMenuToggle}
            src={HamburgerIcon}
            alt="Menu"
          />
        </div>
      </nav>
    </div>
  );
};

export default Header;