import React from "react"
import "./footer.css"
import FooterLogo from "../images/footer-logo.png"
import SocialIcons from "../images/group-17.svg"

export default function Footer() {
  return (
    <div className="footer">
      <div className="foot-footer-3">
        <div className="foot-debits">
          <div className="foot-social-links-parent">
            <div className="foot-logo-link">
              <a href="/" className="foot-logo-link">
                <img className="foot-cic-logo-white-icon" alt="CIC Logo" src={FooterLogo} />
              </a>
            </div>
            <div className="foot-content49">
              <div className="foot-container7">
                <div className="foot-address">Contact us:</div>
                <div className="foot-div">
                  <p className="foot-the-board-plays">
                    <a href="mailto:cicho@cic.lk">cicho@cic.lk</a> | <a href="mailto:info@cic.lk">info@cic.lk</a>
                  </p>
                  <p className="foot-the-board-plays">+94 112328421/6</p>
                  <p className="foot-the-board-plays">+94 112359359</p>
                </div>
              </div>
            </div>
            <div className="foot-content48">
              <div className="foot-address">Our address:</div>
              <div className="foot-level-1-12-container1">
                <p className="foot-the-board-plays">199, Kew Road,</p>
                <p className="foot-the-board-plays">Colombo 2</p>
              </div>
            </div>
            <div className="foot-content47">
              <div className="foot-address">Our social:</div>
              <img className="foot-content-child" alt="" src={SocialIcons} />
            </div>
          </div>
        </div>
        <div className="foot-credits">
          <div className="foot-divider11" />
          <div className="foot-row6">
            <div className="foot-web-design-by">
              Web Design by <a href="https://www.3cs.lk/">3CS</a> | Copyright © 2024 CIC Holdings PLC
            </div>
            <div className="foot-footer-links">
              <a href="#" className="foot-privacy-policy">{`T&C of Sale`}</a>
              <a href="#" className="foot-privacy-policy">Cookie Policy</a>
              <a href="#" className="foot-privacy-policy">Privacy Policy</a>
              <a href="#" className="foot-privacy-policy">Sitemap</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
